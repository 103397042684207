<template>
    <v-container fluid>
        <v-card :loading="loading">
            <Page-Header
                :title="$t('payments.payments')"
                icon="mdi-credit-card-outline"
            />
            <v-expansion-panels class="pa-5" v-model="panel" multiple>
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <v-icon color="primary darken-1">
                            mdi-clipboard-text-search-outline
                        </v-icon>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-form
                            class=""
                            ref="form"
                            v-model="valid"
                            lazy-validation
                        >
                            <v-container>
                                <v-row>
                                    <v-col cols="12" md="6">
                                        <template>
                                            <div>
                                                <v-menu
                                                    ref="menu"
                                                    v-model="menu"
                                                    :close-on-content-click="
                                                        false
                                                    "
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="auto"
                                                >
                                                    <template
                                                        v-slot:activator="{
                                                            on,
                                                            attrs
                                                        }"
                                                    >
                                                        <v-text-field
                                                            hide-details
                                                            outlined
                                                            dense
                                                            :rules="rules"
                                                            v-model="fromDate"
                                                            :label="
                                                                $t(
                                                                    'reports.fromDate'
                                                                )
                                                            "
                                                            readonly
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        ></v-text-field>
                                                    </template>
                                                    <v-date-picker
                                                        v-model="fromDate"
                                                        :active-picker.sync="
                                                            activePicker
                                                        "
                                                        @change="saveDate"
                                                    ></v-date-picker>
                                                </v-menu>
                                            </div>
                                        </template>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <template>
                                            <div>
                                                <v-menu
                                                    ref="menu2"
                                                    v-model="menu2"
                                                    :close-on-content-click="
                                                        false
                                                    "
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="auto"
                                                >
                                                    <template
                                                        v-slot:activator="{
                                                            on,
                                                            attrs
                                                        }"
                                                    >
                                                        <v-text-field
                                                            hide-details
                                                            outlined
                                                            dense
                                                            :rules="rules"
                                                            v-model="toDate"
                                                            :label="
                                                                $t(
                                                                    'reports.toDate'
                                                                )
                                                            "
                                                            readonly
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        ></v-text-field>
                                                    </template>
                                                    <v-date-picker
                                                        v-model="toDate"
                                                        :active-picker.sync="
                                                            activePicker2
                                                        "
                                                        @change="saveDate2"
                                                    ></v-date-picker>
                                                </v-menu>
                                            </div>
                                        </template>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-autocomplete
                                            outlined
                                            hide-details
                                            dense
                                            persistent-hint
                                            v-model="customerGuid"
                                            :items="customers"
                                            item-text="fullName"
                                            item-value="customerGuid"
                                            :label="$t('customer.fullName')"
                                        ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-select
                                            outlined
                                            hide-details
                                            dense
                                            persistent-hint
                                            v-model="payStatus"
                                            :items="payStatusList"
                                            item-text="key"
                                            item-value="value"
                                            :label="$t('bill.payStatus')"
                                        ></v-select>
                                    </v-col>
                                </v-row>
                            </v-container>
                            <v-btn
                                color="primary darken-1 white--text"
                                @click="getData"
                                :disabled="!valid"
                                :min-width="100"
                            >
                                <v-icon
                                    >mdi-clipboard-text-search-outline</v-icon
                                >
                                {{ $t("preview") }}
                            </v-btn>
                        </v-form>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            <v-data-table
                :headers="headers"
                :items="payments"
                :loading="loading"
                :items-per-page="15"
                class="pa-5"
                :search="search"
                :loading-text="$t('loading')"
            >
                <template v-slot:top>
                    <!--Search bar-->
                    <v-text-field
                        dense
                        outlined
                        :loading="loading"
                        v-model="search"
                        append-icon="mdi-magnify"
                        :label="$t('search')"
                        single-line
                        hide-details
                    ></v-text-field>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-row no-gutters>
                        <v-col cols="4"
                            ><v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        v-if="isInRole(37)"
                                        @click="showBill(item)"
                                        class="ml-2 mr-2"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        mdi-eye
                                    </v-icon>
                                </template>
                                <span> {{ $t("preview") }} </span>
                            </v-tooltip></v-col
                        >
                        <v-col cols="4" class="text-end" v-if="!item.isPaid">
                            <v-btn
                                color="primary darken-1"
                                dark
                                outlined
                                small
                                @click="payPayment(item)"
                                >{{ $t("payments.quickPay") }}</v-btn
                            ></v-col
                        >
                        <v-col cols="4" class="text-end" v-if="item.isPaid">
                            <v-tooltip top v-if="isInRole(42)">
                                <template
                                    v-slot:activator="{
                                        on,
                                        attrs
                                    }"
                                >
                                    <v-icon
                                        class="
                                                                        mx-4
                                                                    "
                                        @click="printPayment(item.paymentGuid)"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        mdi-printer-outline
                                    </v-icon>
                                </template>
                                <span> {{ $t("print") }}</span>
                            </v-tooltip></v-col
                        >
                    </v-row>
                </template>
                <template v-slot:[`item.billDate`]="{ item }">
                    {{ item.billDate | moment("yyyy-MM-DD") }}
                </template>
                <template v-slot:[`item.paymentDate`]="{ item }">
                    {{ item.paymentDate | moment("yyyy-MM-DD") }}
                </template>
                <template v-slot:[`item.receivedDate`]="{ item }">
                    <span v-if="item.isPaid">{{
                        item.receivedDate | moment("yyyy-MM-DD HH:mm:ss")
                    }}</span>
                </template>
                <template v-slot:[`item.isPaid`]="{ item }">
                    {{
                        item.isPaid
                            ? $t("payStatus.paid")
                            : $t("payStatus.unpaid")
                    }}
                </template>
                <template v-slot:[`item.paymentAmount`]="{ item }">
                    {{ item.paymentAmount | currency("", item.floatingPoints) }}
                </template>
            </v-data-table>
        </v-card>
        <Confirm-Dialog
            :openDialog="dialogPayConfirm"
            :onClicked="savePayment"
            :onClose="closeConfirm"
            :dialogText="dialogText"
            :toolBarColor="dialogColor"
            :dialogTitle="$t('bill.pay')"
        >
            <template v-slot:body v-if="dialogText != null">
                <v-text-field
                    hide-details
                    outlined
                    type="number"
                    :rules="rules"
                    v-model="paymentAmount"
                    dense
                    :label="$t('voucher.amount')"
                ></v-text-field>
            </template>
            <template v-slot:otherButtons>
                <v-btn @click="saveAndPrint" outlined color="primary darken-1">
                    {{ $t("saveAndPrint") }}
                </v-btn>
            </template>
        </Confirm-Dialog>
    </v-container>
</template>
<script>
import axios from "axios";
import ConfirmDialog from "../../components/ConfirmDialog.vue";
import PageHeader from "../../components/PageHeader.vue";

import moment from "moment";

export default {
    components: {
        ConfirmDialog,
        PageHeader
    },
    data() {
        return {
            paymentAmount: 0,
            dialogColor: "primary",
            dialogText: null,
            payStatus: null,
            customerGuid: null,
            fromDate: moment(new Date()).format("yyyy-MM-DD"),
            toDate: moment(new Date()).format("yyyy-MM-DD"),
            date: null,
            date2: null,
            activePicker: null,
            menu: false,
            activePicker2: null,
            menu2: false,
            valid: true,
            panel: [0, 1],
            paymentObject: null,
            dialogPayConfirm: false,
            loading: true,
            dialogDelete: false,
            editedIndex: -1,
            search: "",
            customers: [],
            headers: [
                {
                    text: this.$t("customer.fullName"),
                    value: "fullName"
                },
                /*  {
                    text: this.$t("bill.billNumber"),
                    value: "billNumber"
                }, */
                {
                    text: this.$t("bill.billDate"),
                    value: "billDate"
                },
                {
                    text: this.$t("payments.paymentNumber"),
                    value: "paymentNumber"
                },
                {
                    text: this.$t("payments.paymentAmount"),
                    value: "paymentAmount"
                },
                {
                    text: this.$t("payments.paymentDate"),
                    value: "paymentDate"
                },
                {
                    text: this.$t("payments.receivedDate"),
                    value: "receivedDate"
                },
                {
                    text: this.$t("payments.isPaid"),
                    value: "isPaid"
                },
                {
                    text: "",
                    value: "actions"
                }
            ],
            payStatusList: [
                { key: this.$t("payStatus.unpaid"), value: false },
                { key: this.$t("payStatus.paid"), value: true },
                { key: this.$t("payStatus.all"), value: null }
            ],
            payments: [],
            editedItem: {
                billGuid: null,
                billSettingGuid: null,
                customerGuid: null,
                accountGuid: null,
                accountContraGuid: null,
                stockGuid: null,
                billType: null,
                billDate: null,
                billNumber: null,
                currencyGuid: null,
                currencyValue: null,
                totalAmount: null,
                totalDiscont: null,
                totalExtra: null,
                totalNet: null,
                notes: null,
                payType: null,
                isLock: null,
                isAccept: null,
                isPaid: null,
                vouchers: null,
                remainingAmount: null,
                isMaintaince: null,
                createdDate: null,
                createdUserGuid: null,
                modifiedDate: null,
                modifiedUserGuid: null,
                branchGuid: null
            },
            defaultItem: {
                billGuid: null,
                billSettingGuid: null,
                customerGuid: null,
                accountGuid: null,
                accountContraGuid: null,
                stockGuid: null,
                billType: null,
                billDate: null,
                billNumber: null,
                currencyGuid: null,
                currencyValue: null,
                totalAmount: null,
                totalDiscont: null,
                totalExtra: null,
                totalNet: null,
                notes: null,
                payType: null,
                isLock: null,
                isAccept: null,
                isPaid: null,
                vouchers: null,
                remainingAmount: null,
                isMaintaince: null,
                createdDate: null,
                createdUserGuid: null,
                modifiedDate: null,
                modifiedUserGuid: null,
                branchGuid: null
            },
            rules: [value => !!value || value == 0 || this.$t("required")]
        };
    },
    created() {
        this.loading = true;
        this.getFilterData();
        this.getData();
    },
    watch: {
        dialogDelete(val) {
            val || this.closeDelete();
        }
    },
    methods: {
        editItem(item) {
            this.editedIndex = this.payments.indexOf(item);
            Object.assign(this.editedItem, item);

            var routeName = "";

            /*
                Hard Coded !!!!!!!!!!!
            */
            if (this.editedItem.billType == "Purchase")
                routeName = "bill.purchaseBill";
            else if (this.editedItem.billType == "Sale")
                routeName = "bill.saleBill";
            else if (this.editedItem.billType == "PurchaseReturn")
                routeName = "bill.purchaseReturnBill";
            else if (this.editedItem.billType == "SaleReturn")
                routeName = "bill.saleReturnBill";
            else if (this.editedItem.billType == "InstallmentsSale")
                routeName = "bill.installmentsSaleBill";

            this.$router.push({
                name: routeName,
                params: { id: this.editedItem.billGuid },
                props: { vType: this.editedItem.billType }
            });
        },
        deleteItem(item) {
            this.editedIndex = this.payments.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialogDelete = true;
        },
        deleteItemConfirm() {
            var deleteItem = this.payments[this.editedIndex];
            axios
                .delete("payments/DeleteBill?id=" + deleteItem.billGuid)
                .then(response => {
                    if (response.data.status == "Successful") {
                        this.$toast.success(
                            this.$t("operationAccomplishedSuccessfully")
                        );
                    } else {
                        this.$toast.error(
                            this.$t("error." + response.data.message)
                        );
                    }
                    this.getData();
                })
                .catch(e => {
                    this.$toast.error(
                        this.$t("AnErrorOccurredDuringTheProcess")
                    );
                    this.getData();
                    console.log(e);
                });

            this.closeDelete();
        },
        close() {
            this.dialog = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
            this.$refs.form.reset();
            this.$refs.form.resetValidation();
        },
        closeDelete() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },
        getData() {
            try {
                this.loading = true;
                axios
                    .get("Payments/Get", {
                        params: {
                            payStatus: this.payStatus,
                            customerGuid: this.customerGuid,
                            fromDate: this.fromDate,
                            toDate: this.toDate
                        }
                    })
                    .then(response => {
                        this.payments = response.data.data;
                    })
                    .catch(e => {
                        this.$toast.error(
                            this.$t("AnErrorOccurredDuringTheProcess")
                        );
                        console.log(e);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            } catch (error) {
                console.log(error);
            }
        },
        getFilterData() {
            try {
                axios
                    .get("Customers/Get")
                    .then(response => {
                        this.customers = response.data.data;
                    })
                    .catch(e => {
                        this.$toast.error(
                            this.$t("AnErrorOccurredDuringTheProcess")
                        );
                        console.log(e);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            } catch (error) {
                console.log(error);
            }
        },
        payPayment(payment) {
            if (payment.installmentState == 1) {
                this.dialogText = this.$t("payments.payFreePaymentConfirm");
                this.dialogColor = "red darken-2";
                this.paymentAmount = payment.paymentAmount;
            } else {
                this.dialogText = null;
                this.dialogColor = "primary";
            }
            this.dialogPayConfirm = true;
            this.paymentObject = payment;
        },
        closeConfirm() {
            this.dialogPayConfirm = false;
        },
        savePayment() {
            try {
                this.paymentObject.paymentAmount = this.paymentAmount;

                axios
                    .post("Payments/QuickPay", this.paymentObject)
                    .then(response => {
                        if (response.data.status == "Successful") {
                            this.getData();
                            this.$toast.success(
                                this.$t("operationAccomplishedSuccessfully")
                            );
                        } else {
                            this.$toast.warning(
                                this.$t("error." + response.data.message)
                            );
                        }
                    })
                    .catch(e => {
                        this.$toast.error(
                            this.$t("AnErrorOccurredDuringTheProcess")
                        );
                        console.log(e);
                    })
                    .finally(() => {
                        this.closeConfirm();
                    });
            } catch (error) {
                console.log(error);
                this.loading = false;
            }
        },
        saveDate(date) {
            this.$refs.menu.save(date);
        },
        saveDate2(date) {
            this.$refs.menu2.save(date);
        },
        printPayment(paymentGuid) {
            axios({
                url: "Payments/PrintPayment",
                method: "GET",
                responseType: "blob",
                params: {
                    paymentGuid: paymentGuid
                }
            }).then(response => {
                var fileURL = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                var fileLink = document.createElement("a");
                fileLink.href = fileURL;
                fileLink.setAttribute("download", "تسديد دفعة.pdf");
                document.body.appendChild(fileLink);
                fileLink.click();
            });
        },
        saveAndPrint() {
            try {
                this.paymentObject.paymentAmount = this.paymentAmount;

                axios
                    .post("Payments/QuickPay", this.paymentObject)
                    .then(response => {
                        if (response.data.status == "Successful") {
                            this.getData();
                            this.$toast.success(
                                this.$t("operationAccomplishedSuccessfully")
                            );
                            this.printPayment(this.paymentObject.paymentGuid);
                        } else {
                            this.$toast.warning(
                                this.$t("error." + response.data.message)
                            );
                        }
                    })
                    .catch(e => {
                        this.$toast.error(
                            this.$t("AnErrorOccurredDuringTheProcess")
                        );
                        console.log(e);
                    })
                    .finally(() => {
                        this.closeConfirm();
                    });
            } catch (error) {
                console.log(error);
            } finally {
                this.loading = false;
            }
        },
        showBill(item) {
            if (!this.isInRole(43))
                this.$router.push({
                    path: `/installmentsSaleBill/${item.billGuid}`
                });
            else
                this.$router.push({
                    path: `/schools/students?customerGuid=${item.customerGuid}`
                });
        }
    }
};
</script>
