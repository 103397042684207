<template>
    <v-dialog persistent v-model="openDialog" max-width="550px">
        <v-card>
            <v-toolbar
                :color="(toolBarColor ? toolBarColor : 'primary') + ' darken-1'"
                dark
            >
                <v-card-title>
                    {{ dialogTitle ? dialogTitle : $t("delete") }}
                </v-card-title>
            </v-toolbar>
            <v-card-title>
                {{
                    dialogText ? dialogText : $t("AreYouSureAboutTheOperation")
                }}
            </v-card-title>
            <v-card-text>
                <slot name="body"></slot>
            </v-card-text>
            <v-card-actions>
                <v-btn color="red darken-1" dark @click="buttonClick">
                    {{ okButtonText ? okButtonText : $t("ok") }}</v-btn
                >
                <slot name="otherButtons"></slot>
                <v-spacer></v-spacer>
                <v-btn text color="primary darken-1" dark @click="closeClick">
                    {{ noButtonText ? noButtonText : $t("cancel") }}</v-btn
                >
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: [
        "onClicked",
        "onClose",
        "openDialog",
        "dialogTitle",
        "dialogText",
        "okButtonText",
        "noButtonText",
        "toolBarColor"
    ],

    methods: {
        buttonClick() {
            if (this.onClicked) {
                this.onClicked();
            }
        },
        closeClick() {
            if (this.onClose) {
                this.onClose();
            } else {
                this.openDialog = false;
            }
        }
    }
};
// how to use
// <confirm-dialog
//   :openDialog="dialogDelete"
//   :onClicked="deleteItemConfirm"
//   :onClose="closeDelete"
// ></confirm-dialog>
</script>
